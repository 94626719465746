<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-app-bar class="mb-6" color="primary" tile dark flat>
      <Logo product="conecta_sign" height="38" full white />
    </v-app-bar>
    <PageLoading v-if="loadingUserInformation" />
    <div
      v-else-if="!userToUpdateTags || userHasUpdatedTags || expiredToken"
      class="d-flex justify-center align-center"
      style="height: 100vh"
    >
      <div class="ma-0 d-flex flex-column justify-center align-center">
        <Logo width="100" height="100" product="conecta_sign" />
        <v-card-title class="text-h5 font-weight-bold mb-6">
          <v-icon :color="userHasUpdatedTags ? 'success' : 'error'" size="25">
            {{ userHasUpdatedTags ? "mdi-check-circle" : "mdi-close-circle" }}
          </v-icon>
          {{
            userHasUpdatedTags
              ? $t("confirmUpdateTags.successfull")
              : $t("confirmUpdateTags.invalidLink")
          }}
        </v-card-title>

        <v-card-actions class="justify-center">
          <v-btn
            x-large
            color="error"
            class="text-none text-body-1 font-weight-medium px-10"
            @click="openLink('https://mail.google.com/')"
          >
            <v-icon left size="25" class="mr-4">mdi-gmail</v-icon>
            {{ $t("common.backToGmail") }}
          </v-btn>
          <v-btn
            dark
            x-large
            color="primarySuite"
            class="text-none text-body-1 font-weight-medium px-10"
            @click="openLink('https://app.conectasuite.com')"
          >
            <Logo
              width="28"
              height="28"
              product="conecta_suite"
              white
              class="mr-4"
            />
            Acessar Conecta Suite
            <v-icon right class="ml-4">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </div>
    <UserTags
      v-else-if="!userHasUpdatedTags && userToUpdateTags"
      :user="userToUpdateTags"
      :userToken="$route.query.token"
      isExternalPage
      resetInputs
      @update="updateUser"
    />
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { openInNewTab } from "@/helpers/services/utils";
import PageLoading from "@/components/PageLoading";
import UserTags from "@/components/sign/tags/UserTags.vue";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";

export default {
  name: "ConfirmUpdateTags",

  components: {
    PageLoading,
    UserTags,
  },

  data() {
    return {
      expiredToken: false,
      loadingUserInformation: true,
      userHasUpdatedTags: false,
      userToUpdateTags: false,
    };
  },

  methods: {
    openInNewTab,

    ...mapMutations([
      "setSnackBar",
      "setCurrentUser",
      "setAcceptedDriveScopes",
    ]),

    openLink(link) {
      openInNewTab(link);
      window.top.close();
    },

    async getUserInformation() {
      const token = this.$route.query.token;

      let url = `${process.env.VUE_APP_API_BASE_URL}/users/user-tags-preview`;
      const params = new URLSearchParams();
      params.append("scopes", INCREMENTAL_SCOPES.DRIVE);

      await this.$axios
        .get(url, {
          headers: {
            Authorization: token,
          },
          params,
        })
        .then(({ data }) => {
          this.userToUpdateTags = data.user;
          this.setCurrentUser(data.user);
          this.setAcceptedDriveScopes(data.accepted_drive_scope);
        })
        .catch(() => {
          this.expiredToken = true;
        })
        .finally(() => {
          this.loadingUserInformation = false;
        });
    },

    updateUser(payload) {
      this.loadingUserInformation = true;

      if (!payload) {
        this.loadingUserInformation = false;
        return;
      }

      delete payload.id_user;

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/confirm-tags-update`;

      const auth = {
        headers: {
          Authorization: this.$route.query.token,
        },
      };

      this.$axios
        .post(url, payload, auth)
        .then(() => {
          this.setSnackBar({
            message: this.$t("confirmUpdateTags.successfull"),
            show: true,
          });

          this.userHasUpdatedTags = true;
        })
        .catch((error) => {
          this.setSnackBar({
            message: this.$t("errorMessages.updateTags"),
            show: true,
            color: "error",
          });
          console.error("confirmUserUpdate(): ", error);
        })
        .finally(() => {
          this.loadingUserInformation = false;
        });
    },
  },

  async beforeMount() {
    await this.getUserInformation();
  },
};
</script>
